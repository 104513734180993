<template>
  <section class="is-primary is-fullheight">
    <div class="login-background">
      <div class="columns is-centered">
        <div class="column">

        </div>
        <div class="column is-one-third">
          <div class="card card-login-form">
            <div class="card-box">
              <header class="card-header">
                <div class="columns row-title">
                  <div class="column column-logo is-full is-size-1">
                    <img width="154px" class="logo" src="../../assets/svgs/Logo/logo_color.svg" alt="">
                  </div>
                </div>
              </header>
              <div class="card-content">
                <div class="content" @keyup.enter="resetPassword()">
                  <div class="content-title">
                    <span>{{$t('login.content_title')}}</span>
                  </div>
                  <div class="field has-text-centered mt-5">
                    <div class="field mt-5">
                      <div class="control has-icons-left">
                        <input type="password" v-model="changePassword.old_password"
                               @keyup.enter="resetPassword()"
                               :placeholder="$t('reset_password.old_password')"
                               :class="{'is-error' : (!vuelidate.changePassword.old_password.required || !vuelidate.changePassword.old_password.minLength || !vuelidate.changePassword.old_password.maxLength) && vuelidate.$dirty}"
                               class="input is-large">
                        <span class="icon is-medium is-left">
                        <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                      </span>
                      </div>
                      <span class="error pt-1" v-if="error_server.old_password && error_server.old_password.length">
                      {{ error_server.old_password[0]}}
                      </span>
                      <span class="error pt-1" v-else-if="error_server.password && error_server.password.length">
                      {{ error_server.password}}
                      </span>
                      <template v-else-if="vuelidate.$dirty">
                      <span class="error pt-1"
                            v-if="!vuelidate.changePassword.old_password.required"> {{
                          $t('change_password.validate.password_required')
                        }}</span>
                        <span class="error pt-1"
                              v-else-if="!vuelidate.changePassword.old_password.minLength"> {{
                            $t('change_password.validate.password_minlength')
                          }}</span>
                        <span class="error pt-1"
                              v-else-if="!vuelidate.changePassword.old_password.maxLength"> {{
                            $t('change_password.validate.password_maxlength')
                          }}</span>
                      </template>
                      <span class="error pt-1" v-else-if="error_server.old_password && error_server.old_password.length">
                    {{ error_server.old_password[0]}}
                    </span>
                      <span class="error pt-1" v-else-if="error_server.password && error_server.password.length">
                    {{ error_server.password}}
                    </span>
                    </div>
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="password" v-model="changePassword.new_password"
                               @keyup.enter="resetPassword()"
                               :placeholder="$t('reset_password.password')"
                               :class="{'is-error' : (!vuelidate.changePassword.new_password.required || !vuelidate.changePassword.new_password.minLength || !vuelidate.changePassword.new_password.maxLength) && vuelidate.$dirty}"
                               class="input is-large">
                        <span class="icon is-medium is-left">
                        <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                      </span>
                      </div>
                      <span class="error pt-1" v-if="error_server.new_password && error_server.new_password.length">
                    {{ error_server.new_password[0] }}
                    </span>
                      <template v-else-if="vuelidate.$dirty">
                      <span class="error pt-1"
                            v-if="!vuelidate.changePassword.new_password.required"> {{
                          $t('change_password.validate.new_password_required')
                        }}</span>
                        <span class="error pt-1"
                              v-else-if="!vuelidate.changePassword.new_password.minLength"> {{
                            $t('change_password.validate.new_password_minlength')
                          }}</span>
                        <span class="error pt-1"
                              v-else-if="!vuelidate.changePassword.new_password.maxLength"> {{
                            $t('change_password.validate.new_password_maxlength')
                          }}</span>
                      </template>
                    </div>
                    <div class="field m__bottom--30 ">
                      <div class="control has-icons-left">
                        <input type="password" v-model="changePassword.password_confirmation"
                               @keyup.enter="resetPassword()"
                               :placeholder="$t('profile.input_field.password_confirm.placeholder')"
                               :class="{'is-error' : (!vuelidate.changePassword.password_confirmation.required || !vuelidate.changePassword.password_confirmation.sameAs) && vuelidate.$dirty}"
                               class="input is-large">
                        <span class="icon is-medium is-left">
                        <img class="icon-input-pass" src="../../assets/svgs/Icon/pass.svg" alt="">
                      </span>
                      </div>
                      <span class="error pt-1"
                            v-if="error_server.password_confirmation && error_server.password_confirmation.length">
                    {{ error_server.password_confirmation[0] }}
                    </span>
                      <template v-else-if="vuelidate.$dirty">
                      <span class="error pt-1"
                            v-if="!vuelidate.changePassword.password_confirmation.required"> {{
                          $t('change_password.validate.password_confirmation_required')
                        }}</span>
                        <span class="error pt-1"
                              v-else-if="!vuelidate.changePassword.password_confirmation.sameAs"> {{
                            $t('change_password.validate.password_confirmation_not_match')
                          }}</span>
                      </template>

                    </div>
                    <div class="field has-text-centered mt-4 button-box">
                      <button @click="resetPassword()" class="button btn-login is-align-items-flex-end">
                        {{ $t('buttons.submit') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </section>
</template>


<script>
  import {sameAs, maxLength, minLength, required} from "vuelidate/lib/validators";
  import {AuthService} from '@/services';
  import {ACTION_SAVE_AUTH_USER} from "@/stores/auth/actions";

  export default {
    name: "ChangePassword",
    data() {
      return {
        changePassword: {
          old_password: '',
          new_password: '',
          password_confirmation: '',
        },
        error_server: {},
        submitAvailable: true,
      };
    },
    validations: {
      changePassword: {
        old_password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(16)
        },
        new_password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(16)
        },
        password_confirmation: {
          required,
          sameAs: sameAs(function () {
            return this.changePassword.new_password
          })
        },
      },
    },
    methods: {
      resetPassword() {
        this.error_server = {}
        this.vuelidate.$touch();
        if (!this.vuelidate.$invalid && this.submitAvailable)
        {
          this.submitAvailable = false
          AuthService.changePassword(this.changePassword).then((res) => {
            this.submitAvailable = true
            if (res) {
              this.$store.dispatch(ACTION_SAVE_AUTH_USER).then(() => {
                this.$router.push({name: "SearchDocuments"}, () => {})
              })
              this.$toast.success(this.$t('change_password.messages.success'))
            } else {
              this.$toast.error(this.$t('forgot_password.message_error'))
            }
          }).catch((err) => {
            this.submitAvailable = true
            this.error_server = err.data.errors
            console.log(this.error_server)
            // this.$toast.error(this.$t('forgot_password.message_error'))
          })
        }
      },
      // async updateAuthUser() {
      //   await AuthService.getAuthUser().then((res) => {
      //     if (res) {
      //       localStorage.setItem('userInfo', JSON.stringify(res.data))
      //       this.$nextTick(() => {
      //         this.$router.push({name: "SearchDocuments"}, () => {})
      //       })
      //     }
      //   })
      // }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/login.scss";

  .btn-reset-password {
    background: #c585b2  0 0 no-repeat padding-box;
    border-radius: 45px !important;
    opacity: 1;
    color: #ffffff;
    width: 250px;
    height: 60px;
    font-size: 28px;
    font-weight: bold;
  }


</style>
